import styled from "@emotion/styled"
import { mq, theme } from '../../util/theme'
import { css } from "@emotion/core"

import Check from "../../images/checkmark.svg"
import Acc from '../../images/carat-mob.svg'

export const SectionWrap = styled.div`
  max-width: 615px;
  padding-bottom: 3rem;
  ${mq[2]} {
    margin-right: 2rem;
  }

  @media print{
    margin-right: 0;
  }
`;

export const Section = styled.section`
  padding: 4rem 0;
  margin-bottom: 1rem;
  border-bottom: 10px solid ${props => props.theme.colors.gray};
  &.no-border {
    border: none;
  }
  &.small {
    border: none;
    margin: 0;
    ${mq[2]} {
      border-bottom: 10px solid ${props => props.theme.colors.gray};
    }
    h2{
      font-size: ${props => props.theme.fontSizes.h2};
    }
  }
  &:last-of-type {
      margin-bottom: 0;
  }
  &:only-of-type {
      border: none;
      padding-bottom: 4rem;
      margin-top: -1rem;
  }

  @media print{
    padding: 1rem 0;
    margin-bottom: .25rem;
    border-bottom: 5px solid ${props => props.theme.colors.gray};
    &.small {
      border: none;
      margin: 0;
      ${mq[2]} {
        border-bottom: 5px solid ${props => props.theme.colors.gray};
      }
    }
  }
`;

export const MobLogo = styled.img`
    display: block;
    max-width: 140px;
    width: 100%;
    ${mq[2]} {
        display: none;
    }
`;

export const SectionTitle = styled.h2`
  color: ${props => props.theme.colors.blue};
  font-size: ${props => props.theme.fontSizes.h1};
  font-family: ${props => props.theme.fonts.heading};
  font-weight:  ${props => props.theme.weights.reg};
  line-height: 1.3;
  margin: 0 0 1.5rem;

  @media print{
    max-width: fit-content;
    margin: 15px 0 30px 0;
    font-size: 36px;
    line-height: 1.12;
  }
`;

export const SectionSubtitle = styled.h3`
  color: ${props => props.theme.colors.blue};
  font-size: ${props => props.theme.fontSizes.h2};
  font-family: ${props => props.theme.fonts.heading};
  font-weight:  ${props => props.theme.weights.reg};
  line-height: 1.5;
  margin: -1rem 0 1.5rem;
  &.is-sub {
    color: ${props => props.theme.colors.copy};
    font-size: ${props => props.theme.fontSizes.h3};
    font-family: ${props => props.theme.fonts.copy};
    font-weight:  ${props => props.theme.weights.bold};
  }
  &.has-top-marg {
    margin-top: 4rem;
  }
  &.centered {
    margin: 1rem 0 .5rem;
    text-align: center;
  }
`;

export const SectionCopy = styled.div`
  color: ${props => props.theme.colors.copy};
  font-size: ${props => props.theme.fontSizes.regPlus};
  font-family: ${props => props.theme.fonts.copy};
  font-weight:  ${props => props.theme.weights.reg};
  line-height: 1.5;
  margin: 0 0 1rem;
  &.is-form {
    margin: -.5rem 0 2rem;
  }
  &.is-disclaimer {
    font-size: ${props => props.theme.fontSizes.sm};
  }
  &.centered {
    text-align: center;

  }
  a {
    color: ${props => props.theme.colors.copy};
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.colors.blue};
      cursor: pointer;
    }
  }
  p{
    display: inline;
  }
  
  @media print{
    max-width: fit-content;
    margin: 0 0 .75rem;
  }
`;

export const btn = css`
  color: ${theme.colors.white};
  background: ${theme.colors.robins};
  font-size: ${theme.fontSizes.h3};
  font-weight:  ${theme.weights.bold};
  text-transform: uppercase;
  text-align: center;
  max-width: 400px;
  display: block;
  text-decoration: none;
  width: fit-content;
  padding: 1rem 3rem;
  margin: 0;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    background: ${theme.colors.blue};
    color: ${theme.colors.white};
  }
`;

export const InlineLink = styled.a`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fontSizes.h3};
  font-family: ${props => props.theme.fonts.copy};
  font-weight:  ${props => props.theme.weights.reg};
  line-height: 1.5;
  &:hover {
    color: ${props => props.theme.colors.teal};
  }
`;

export const List = styled.ul`
    list-style: none;
    margin: 0 0 .5rem;
    padding: 0;
    &:last-of-type{
      margin: 0 0 2rem;
    }
    @media print{
      list-style: disk;
    }
`;

export const ListItem = styled.li`
    position: relative;
    color: ${props => props.theme.colors.copy};
    font-size: ${props => props.theme.fontSizes.h3};
    font-family: ${props => props.theme.fonts.copy};
    font-weight:  ${props => props.theme.weights.reg};
    line-height: 1.5;
    &.check{
      margin: 0 0 0.5rem;
      padding-left: 2.5rem;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0.6rem;
        background: url(${Check}) no-repeat center/cover;
        width: 1rem;
        height: 9px;
      }
    }
    &.dot {
      padding-left: 1.5rem;
      margin: 0;
      &:before {
        content: "•";
        position: absolute;
        left: 0;
        top: 0;
        color: ${props => props.theme.colors.blue};
        font-size: ${props => props.theme.fontSizes.h3};
        background: transparent;
      }
    }
    &.dash {
      padding-left: 1.5rem;
      margin: 0 0 0.5rem;
      &:before {
        content: "-";
        position: absolute;
        left: 0;
        top: 0;
        color: ${props => props.theme.colors.blue};
        font-size: ${props => props.theme.fontSizes.h3};
        background: transparent;
      }
    }
`;

export const SidebarAside = styled.section`
  width: 100%;
  background: ${props => props.theme.colors.gray};
  display: block;
  padding: 1.5rem;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    background: ${props => props.theme.colors.copy};
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100vw;
    height: 100%;
    width: 100%;
    z-index: 1000;
  }
  ${mq[2]} {
    display: none;
  }
`;

export const SidebarHeader = styled.h3`
  color: ${props => props.theme.colors.copy};
  font-size: ${props => props.theme.fontSizes.h3};
  font-family: ${props => props.theme.fonts.heading};
  font-weight:  ${props => props.theme.weights.bold};
  line-height: 1.5;
  margin: 0;
`;

export const SidebarCopy = styled.p`
  color: ${props => props.theme.colors.copy};
  font-size: ${props => props.theme.fontSizes.sm};
  font-family: ${props => props.theme.fonts.copy};
  font-weight:  ${props => props.theme.weights.reg};
  line-height: 1.5;
  margin: 0 0 1.5rem;
  &.is-last {
    margin: 0;
  }
  a {
    color: ${props => props.theme.colors.copy};
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.colors.blue};
    }
  }
`;

export const SidebarLink = styled.a`
  color: ${props => props.theme.colors.white};
  &:hover {
     color: ${props => props.theme.colors.teal};
  }
`;

export const LocationsHeader = styled.h3`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fontSizes.h3};
    font-family: ${props => props.theme.fonts.heading};
    font-weight:  ${props => props.theme.weights.bold};
    margin: 3rem 0 0;
    padding: .5rem 1rem;
    border: none;
    border-top: 1px solid ${props => props.theme.colors.gray};
    transition: all .15s ease-in-out;
    background: ${props => props.theme.colors.blue};
    cursor: pointer;
    &:after {
      content: '';
      background: url(${Acc}) no-repeat center/cover;
      display: block;
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 1rem;
      margin: auto;
      height: .5rem;
      width: 1rem;
      transform: rotate(180deg);
      transform-origin: center center;
      transition: transform .2s ease-in-out;
    }
    &.is-active {
      &:after {
        transform: none;
      }
    }
`;

export const LocationHolder = styled.div`
  max-height: 0;
  overflow: hidden;
  &.is-active {
    max-height: 100%;
    padding: 1rem 0;
  }
`;

export const LocationWrap = styled.div`
   margin: 0 0 .75rem;
  ${mq[1]} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const LocationCopy = styled.p`
  color: ${props => props.theme.colors.copy};
  font-size: ${props => props.theme.fontSizes.h3};
  font-family: ${props => props.theme.fonts.copy};
  font-weight:  ${props => props.theme.weights.reg};
  line-height: 1.5;
  margin: 0;
  a {
    color: ${props => props.theme.colors.copy};
    font-size: ${props => props.theme.fontSizes.h3};
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.colors.blue};
    }
  }
`;

export const link = css`
    color: ${props => props.theme.colors.copy};
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.colors.blue};
    }
`;

export const LocationMap = styled.img`
  border: 2px solid ${props => props.theme.colors.border};
  margin-bottom: 1rem;
`;

export const ShareCopy = styled.p`
  display: block;
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fontSizes.h3};
  font-family: ${props => props.theme.fonts.copy};
  font-weight:  ${props => props.theme.weights.bold};
  line-height: 1.5;
  margin: 1.5rem 0 .5rem;
  ${mq[2]} {
    display: none;
  }
`;

export const ShareBtns = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 140px;
  ${mq[2]} {
    display: none;
  }
`;

export const shareIcon = css`
    display: block;
    max-width: 35px;
    width: 100%;
    &:hover {
      g {
        fill: ${theme.colors.blue};
      }
    }
`;

export const References = styled.ol`
  margin: 0;
  padding: 0 0 0 1rem;
  li {
    color: ${props => props.theme.colors.copy};
    font-size: ${props => props.theme.fontSizes.tiny};
    margin: 0 0 0.5rem;
    padding-left: 1rem;
    line-height: 1.25;
  }
`

export const PrintSidebarWrap = styled.div`
  display: none;

  @media print{
    display: block;
  }
`;

export const PrintHeaderWrap = styled.div`
  display: none;

  @media print{
    display: block;
  }
`;

export const PrintHeaderLogo = styled.div`
  .tag{
    position: absolute;
    right: 1.5em;
    top : 1.5em;
  }
`;